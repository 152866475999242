var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "maca-modal",
        {
          ref: "modal",
          attrs: { titulo: "Nueva Zona", impedirClose: _vm.impedirClose }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.formRules,
                "label-width": "150px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Nombre", prop: "nombre" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.nombre,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "nombre", $$v)
                      },
                      expression: "form.nombre"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Descripción", prop: "descripcion" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea" },
                    model: {
                      value: _vm.form.descripcion,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "descripcion", $$v)
                      },
                      expression: "form.descripcion"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("maca-boton-guardar", {
                    attrs: { onSubmit: _vm.onSubmit }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }