<template>
  <div>
    <maca-modal titulo="Modificar Zona" :impedirClose="impedirClose" ref="modal">
      <el-form :model="form" ref="form" :rules="formRules" label-width="150px" v-loading="cargando">
        <el-form-item label="Nombre" prop="nombre">
          <el-input v-model="form.nombre"></el-input>
        </el-form-item>
        <el-form-item label="Descripción" prop="descripcion">
          <el-input type="textarea" v-model="form.descripcion"></el-input>
        </el-form-item>
        <el-form-item>
          <maca-boton-guardar :onSubmit="onSubmit"></maca-boton-guardar>
        </el-form-item>
      </el-form>
    </maca-modal>
  </div>
</template>

<script>
export default {
  name: "modificar-zona",
  data() {
    return {
      form: {
        nombre: "",
        descripcion: "",
      },
      impedirClose: false,
      cargando: false,
      id: 0,

      formRules: {
        nombre: [
          {
            required: true,
            message: "Por favor introduzca el nombre.",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    abrir(id) {
      this.$refs.modal.abrir();

      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.id = id;
      this.getDatos();
    },
    cerrar() {
      this.$refs.modal.cerrar();
    },
    async getDatos() {
      this.cargando = true;

      let respuestaApi = await this.$api.get(
        this.$api.URL + "/zona/obtenerDatos?zonaID=" + this.id,
        this.$usuarioToken()
      );

      this.cargando = false;

      if (respuestaApi != null) {
        this.form.nombre = respuestaApi.nombre;
        this.form.descripcion = respuestaApi.descripcion;
      } else {
        this.cerrar();
      }
    },
    onSubmit(afterSubmit) {
      this.$refs.form.validate(async (formOk) => {
        if (!formOk) {
          afterSubmit();
          return false;
        }

        this.impedirClose = true;

        let postOk = await this.postApi();

        this.impedirClose = false;
        afterSubmit();

        if (postOk) {
          this.$emit(this.$eventStrings._actualizarTabla);
          this.cerrar();
        }
      });
    },
    async postApi() {
      // Hacer Post
      let params = {
        zonaID: this.id,
        nombre: this.form.nombre,
      };

      if (this.form.descripcion != "") {
        params.descripcion = this.form.descripcion;
      }

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/zona/actualizar",
        params,
        this.$usuarioToken()
      );
      //

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Zona actualizada con éxito!",
          type: "success",
        });

        return true;
      } else if (respuestaApi.estado == -3) {
        this.$message({
          message: "La zona ya existe. Por favor elija otro nombre.",
          type: "error",
        });
      } else if (respuestaApi.estado === 0) {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0
        });
      } else {
        this.$message({
          message: respuestaApi.mensaje,
          type: "error",
          showClose: true,
          duration: 0
        });
      }

      return false;
    },
  },
};
</script>
